import { inject, Injectable } from '@angular/core';
import { StaticPageService } from '../../../page/static-page/static-page.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { StaticContentService } from '../static-content.service';
import { StaticContentType } from '../../../../environments/environment';
import { CmsApiService } from '../api/cms-api.service';
import { UserService } from './user.service';
import { TournamentsService } from '../../../page/tournaments/tournaments.service';
import { ITournament } from '../../../page/tournaments/tournaments.interface';
import { useStore } from 'ngx-unificator/store';
import { ManagerItem, UserVipConditionItem, VipLevel, VipPage, VipStore, VipStoreEntity } from '../../store/vip.store';
import { GlobalStoreName } from '../../store';
import { CookieService } from 'ngx-cookie-service';
import { UserBonusesService } from './user-bonuses.service';
import { ModalService } from '../../modal-v2/modal.service';
import { NotificationCenterService } from '../../modules/notification-center/notification-center.service';
import { GroupsService } from '../groups.service';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserVipService {

  public vipLevelsSliderConfig = {
    loop: true,
    slides: {
      perView: 3,
      spacing: 20,
    },
    breakpoints: {
      '(max-width: 992px)': {
        slides: {
          perView: 1.2,
          spacing: 10,
        },
      },
    },
    initial: 0,
  };

  /**
   * Services
   * @private
   */
  private _staticPage$: StaticPageService = inject(StaticPageService);
  private _staticContent$: StaticContentService = inject(StaticContentService);
  private _cmsApi$: CmsApiService = inject(CmsApiService);
  private _user$: UserService = inject(UserService);
  private _group$: GroupsService = inject(GroupsService);
  private _tournaments$: TournamentsService = inject(TournamentsService);
  private _cookie$: CookieService = inject(CookieService);
  private _userInfo = inject(UserInfoService);
  private _notifications$: NotificationCenterService = inject(NotificationCenterService);
  public bonuses$: UserBonusesService = inject(UserBonusesService);
  public modal$: ModalService = inject(ModalService);

  private _reload$ = new Subject<void>();

  /**
   * User vip store
   */
  public vipStore = useStore<VipStoreEntity>(GlobalStoreName.VIP_STORE);

  /**
   * Tournaments
   */
  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  /**
   * Page getter
   */
  public get store(): VipStore {
    return this.vipStore.storeSignal();
  }

  /**
   * Toggle dropdown managers
   */
  public toggleManagers() {
    this.vipStore.storeSignal.update(store => {
      return {
        ...store,
        isShowManagers: !store.isShowManagers,
      };
    });
  }

  /**
   * Show vip club member modal
   */
  public async showVipClubModal() {
    const component =
      await import('../../modal-v2/components/lazy/vip-modal/vip-modal.component');
    await this.modal$.openLazy(component?.VipModalComponent, {
      template: 'CLEAR',
    });
  }

  /**
   * Get vip page from CMS
   */
  public loadVipPage$(): Observable<VipPage> {
    return this._staticPage$.item({ slug: 'vip' }).pipe(
      filter(page => !!page?.length),
      map(page => page[0]),
      map((page) => {
        const managers: ManagerItem[] | any =
          [...Object.values(page?.Managers)];
        return {
          ...page,
          Benefits: [...Object.values(page?.Benefits)],
          HowGetVip: [...Object.values(page?.HowGetVip)],
          VipAdvantagesFeatures:
            [...Object.values(page?.VipAdvantagesFeatures)],
          Managers: managers,
          CurrentManager: managers
              .find(item => this._user$?.info?.statuses
                ?.map(status => status?.id).includes(item.managerGroup)) ||
            null,
        };
      }),
    );
  }

  public reloadVipLevels(): void {
    this._reload$.next();
  }

  public vipLevels$ = this._reload$.pipe(
    switchMap(() => {
      this._setLoadingState(true);
      return combineLatest([
        this._staticContent$.list({ type: StaticContentType.VIP_LEVELS }),
        this._getNumberOfDepositDaysPerMonth$(),
        this._getMedianDepositPerTransaction$(),
        this._getWageringPerMonth$(),
        this._getDifferentProviders$(),
        this._getConsecutiveMonthOfActivity$(),
      ]).pipe(
        filter(([vipLevels]) => !!vipLevels),
        map(([
               vipLevels,
               numberOfDepositsDaysPerMonth,
               medianDepositPerTransaction,
               wageringPerMonth,
               differentGameProviders,
               consecutiveMonthOfActivity,
             ]: any) => {
          this._updateStoreFetchedData(
            numberOfDepositsDaysPerMonth,
            medianDepositPerTransaction,
            wageringPerMonth,
            differentGameProviders,
            consecutiveMonthOfActivity,
          );
          return this._processVipLevels(vipLevels);
        }),
        tap((vipLevels: VipLevel[]) => {
          this._updateVipStore(vipLevels);
          this._updateVipGroups();
        }),
      );
    }),
  );

  /**
   * Show vip club modal and add custom notification channel
   * @private
   */
  private async _resolveAdditionalData() {
    await this.showVipClubModal();
    this._notifications$.vipChannel$.next(true);
  }

  /**
   * Set loading page state
   * @param isLoading
   * @private
   */
  private _setLoadingState(isLoading: boolean): void {
    this.vipStore.storeSignal.update(store => ({
      ...store,
      isLoadingPage: isLoading,
    }));
  }

  /**
   * Convert conditions object to proper format
   */
  private _convertConditions(conditions: Record<string, any>): UserVipConditionItem[] {
    return Object.values(conditions)?.map(({ min, max, reward, ...rest }) => ({
      ...rest,
      min: Number(min),
      max: max === 'Infinity' ? Infinity : Number(max),
      reward: Number(reward),
    })) || [];
  }


  /**
   * Fetch data to calculate reward points
   * @param apiMethod
   * @param requireCurrency
   * @private
   */
  private _fetchData$<T>(
    apiMethod: (id: string, currency?: string) => Observable<T>,
    requireCurrency: boolean = false,
  ): Observable<T> {
    return this._user$.auth$.pipe(
      switchMap((auth) => {
        if (auth) {
          const userId = this._user$.info.id;
          return (requireCurrency ?
            apiMethod(userId, this._user$.info.currency) :
            apiMethod(userId)).pipe(
            map((response: any) => response?.data?.data ?? {} as T),
            catchError(() => of({} as T)),
          );
        } else {
          return of({} as T);
        }
      }),
    );
  }

  /**
   * Get number of deposit days
   * @private
   */
  private _getNumberOfDepositDaysPerMonth$():
    Observable<{ list: { userDepositsDays: number | string } }> {
    return this._fetchData$(
      this._cmsApi$.numberOfDepositDaysPerMonth.bind(this._cmsApi$));
  }

  /**
   * Get deposit count
   * @private
   */
  private _getDepositCountPerMonth$():
    Observable<{ depositsCount: number | string }> {
    return this._fetchData$(
      this._cmsApi$.depositCountPerMonth.bind(this._cmsApi$), true);
  }

  /**
   * Get median deposit per transaction
   * @private
   */
  private _getMedianDepositPerTransaction$():
    Observable<{ medianDeposit: number | string, depositsCount: number | string }> {
    return this._fetchData$(
      this._cmsApi$.medianDepositPerTransaction.bind(this._cmsApi$), true);
  }

  /**
   * Get wager per month
   * @private
   */
  private _getWageringPerMonth$():
    Observable<{ wagering: number | string }> {
    return this._fetchData$(
      this._cmsApi$.wageringPerMonth.bind(this._cmsApi$), true);
  }

  /**
   * Get different providers
   * @private
   */
  private _getDifferentProviders$():
    Observable<{ providerCount: number | string }> {
    return this._fetchData$(
      this._cmsApi$.differentGameProviders.bind(this._cmsApi$));
  }

  /**
   * Get consecutive activity by month
   * @private
   */
  private _getConsecutiveMonthOfActivity$():
    Observable<{ consecutiveMonthActivity: number | string }> {
    return this._fetchData$(
      this._cmsApi$.consecutiveMonthOfActivity.bind(this._cmsApi$));
  }

  /**
   * Process vip levels
   * @param vipLevels
   * @private
   */
  private _processVipLevels(vipLevels: any[]): VipLevel[] {
    return vipLevels.map(item => this._createVipLevel(item));
  }

  /**
   * Mapping data for each vip level
   * @param item
   * @private
   */
  private _createVipLevel(item: any): VipLevel {

    const rewardKeys = {
      numberOfDepositsDaysPerMonth: 'NumberOfDepositsDaysPerMonth',
      depositCountPerMonth: 'DepositCountPerMonth',
      medianDepositPerTransaction: 'MedianDepositPerTransaction',
      wageringPerMonth: 'WageringPerMonth',
      differentGameProviders: 'DifferentGameProviders',
      consecutiveMonthOfActivity: 'ConsecutiveMonthActivity',
    };

    const rewards: {
      [key: string]: { points: number, conditions: UserVipConditionItem[] }
    } = Object.entries(rewardKeys).reduce((acc, [key, value]) => {
      const conditions = this._convertConditions(item?.[value]);
      acc[key] = {
        points: this._getReward(this.store[key], conditions),
        conditions,
      };
      return acc;
    }, {});

    const totalRewards = this._calculateTotalRewards(rewards);
    const VPointsToReach = Number(item?.VPointsToReach);

    const HowEarnVPoints = Object.keys(item?.HowEarnVPoints).map((key) => {
      return {
        ...item?.HowEarnVPoints[key],
        ...this._applyDoneConditions(rewards)[key],
      };
    });

    return {
      ...item,
      HowEarnVPoints,
      Managers: Object.values(item?.Managers || {}),
      Benefits: this._parseHtmlBenefitsToJson(item?.Benefits),
      TotalReward: totalRewards >= VPointsToReach ? VPointsToReach
        : totalRewards,
      VPointsToReach,
      ProgressPercent: (totalRewards / Number(item?.VPointsToReach)) * 100,
    };
  };

  /**
   * Calculate total vip points
   * @private
   */
  private _calculateTotalRewards(rewards): number {
    return (
      rewards.numberOfDepositsDaysPerMonth?.points +
      rewards.depositCountPerMonth?.points +
      rewards.medianDepositPerTransaction?.points +
      rewards.wageringPerMonth?.points +
      rewards.differentGameProviders?.points +
      rewards.consecutiveMonthOfActivity?.points
    );
  };

  private _getReward(
    value: number,
    conditions: UserVipConditionItem[]): number {
    const condition = conditions
      ?.find(({ min, max }) => value >= min && value <= max);
    return condition ? condition.reward : 0;
  };

  /**
   * Detect current / next / prev user levels
   * @param vipLevels
   * @private
   */
  private _updateVipStore(vipLevels: VipLevel[]): void {

    const { currentMonthGroup } = this._resolveCurrentAndPrevMonth();

    const currentMonthUserLevelIdx = this.store.userTransferGroups
      .findIndex(group =>
        this.store.userStatuses.includes(group.monthGroup));

    const currentMonthLevelIdx = this.store.userTransferGroups
      .findIndex(group => group.monthGroup === currentMonthGroup);

    let currentLevelIdx = vipLevels.findIndex(item =>
      this.store.userStatuses.includes(item.LevelGroup),
    );

    if (currentMonthUserLevelIdx > currentMonthLevelIdx) {
      currentLevelIdx = currentLevelIdx - 1;
    }

    this.vipLevelsSliderConfig.initial = currentLevelIdx === -1 ?
      0 : currentLevelIdx;

    const currentLevel = currentLevelIdx !== -1 ?
      vipLevels[currentLevelIdx] : null;
    const nextLevel = vipLevels[currentLevelIdx + 1] || null;
    const prevLevel = vipLevels[currentLevelIdx - 1] || null;

    const findNextLevelInGroups = this.store.userStatuses.find(status =>
      nextLevel?.LevelGroup === status);

    const nextLevelIdx = vipLevels.findIndex(level =>
      level?.LevelGroup === findNextLevelInGroups);

    this.vipStore.storeSignal.update(store => ({
      ...store,
      currentVipUserLevel: currentLevel,
      nextVipUserLevel: nextLevel,
      prevVipUserLevel: prevLevel,
      vipLevels,
      isLoadingPage: false,
      isShowProgressBar: !(nextLevelIdx > currentLevelIdx),
    }));
  };

  private _updateStoreFetchedData(
    numberOfDepositsDaysPerMonth: { list: { userDepositsDays: number | string } },
    medianDepositPerTransaction: { medianDeposit: number | string, depositsCount: number | string },
    wageringPerMonth: { wagering: number | string },
    differentGameProviders: { providerCount: number | string },
    consecutiveMonthOfActivity: { consecutiveMonthActivity: number | string },
  ) {
    this.vipStore.storeSignal.update(store => {
      return {
        ...store,
        userStatuses: this._user$?.info?.statuses
          ?.map(status => status?.id) || [],
        numberOfDepositsDaysPerMonth: this._parseNumber(
          numberOfDepositsDaysPerMonth?.list?.userDepositsDays,
        ),
        depositCountPerMonth: this._parseNumber(
          medianDepositPerTransaction?.depositsCount,
        ),
        medianDepositPerTransaction: this._parseNumber(
          medianDepositPerTransaction?.medianDeposit,
        ),
        wageringPerMonth: this._parseNumber(
          wageringPerMonth?.wagering,
        ),
        differentGameProviders: this._parseNumber(
          differentGameProviders?.providerCount,
        ),
        consecutiveMonthOfActivity: this._parseNumber(
          consecutiveMonthOfActivity?.consecutiveMonthActivity,
        ),
      };
    });
  }

  private _parseNumber(value: any): number {
    if (typeof value === 'string' && value.includes('e')) {
      return Number(value);
    }
    return Number(value) || 0;
  }

  /**
   * Add/remove vip groups from FE
   * @private
   */
  private _updateVipGroups() {
    const { nextMonthGroup } = this._resolveCurrentAndPrevMonth();

    const shouldAddNextGroup =
      !this._group$.isExistGroup(nextMonthGroup) &&
      this.store.nextVipUserLevel?.TotalReward >= this.store.nextVipUserLevel?.VPointsToReach;

    if (shouldAddNextGroup) {
      const nextLevelGroup = this.store.nextVipUserLevel?.LevelGroup;
      const matchingLevel = this.store.vipLevels
        .filter(item => this.store.userStatuses
          .includes(item.LevelGroup))[0]?.LevelGroup;
      const matchingMonth = this.store.userTransferGroups
        .filter(item => this.store.userStatuses
          .includes(item.monthGroup))[0]?.monthGroup;

      this._group$.addToGroup([nextMonthGroup, nextLevelGroup]).pipe(
        switchMap(() => this._user$.getUserInfo()),
        switchMap(() => {
          this._resolveAdditionalData();
          if (matchingLevel && matchingMonth) {
            return this._group$.removeFromGroup([
              matchingLevel, matchingMonth,
            ]);
          } else {
            return of(null);
          }
        }),
      ).subscribe();
    }
  }

  /**
   * Resolve current / next / prev months according month group
   * @private
   */
  private _resolveCurrentAndPrevMonth():
    {
      currentMonthGroup: string,
      prevMonthGroup: string,
      nextMonthGroup: string,
    } {
    const currentMonth =
      !this._userInfo.isCA ? new Date().toLocaleString('en', {
        month: 'short',
        timeZone: 'UTC',
      }) : new Date().toLocaleString('en', { month: 'short' });

    const currentMonthIndex = this.store.userTransferGroups
      .findIndex(group => group.date === currentMonth);

    const currentMonthGroup =
      this.store.userTransferGroups[currentMonthIndex]?.monthGroup;

    const nextMonthGroup =
      this.store.userTransferGroups[
        currentMonthIndex === 11 ? 0 : currentMonthIndex + 1
        ]?.monthGroup;

    const prevMonthGroup =
      this.store.userTransferGroups[
        currentMonthIndex === 0 ? 11 : currentMonthIndex - 1
        ]?.monthGroup;

    return {
      currentMonthGroup,
      prevMonthGroup,
      nextMonthGroup,
    };
  }

  /**
   * Apply done conditions for each type of reward
   * @private
   */
  private _applyDoneConditions(rewards: any) {

    const result: { [key: string]: { [condition: string]: boolean } } = {};

    result.UserDepositDays = {
      firstCondition:
        rewards.numberOfDepositsDaysPerMonth?.conditions[1] ? rewards.numberOfDepositsDaysPerMonth?.points >=
          rewards.numberOfDepositsDaysPerMonth?.conditions[0]?.reward &&
          rewards.numberOfDepositsDaysPerMonth?.points < rewards.numberOfDepositsDaysPerMonth?.conditions[1]?.reward :
          rewards.numberOfDepositsDaysPerMonth?.points >=
          rewards.numberOfDepositsDaysPerMonth?.conditions[0]?.reward,
      secondCondition:
        rewards.numberOfDepositsDaysPerMonth?.points >=
        rewards.numberOfDepositsDaysPerMonth?.conditions[1]?.reward,
    };

    result.DepositCountPerMonth = {
      firstCondition:
        rewards.depositCountPerMonth?.conditions[1] ? rewards.depositCountPerMonth?.points >=
          rewards.depositCountPerMonth?.conditions[0]?.reward &&
          rewards.depositCountPerMonth?.points < rewards.depositCountPerMonth?.conditions[1]?.reward :
          rewards.depositCountPerMonth?.points >=
          rewards.depositCountPerMonth?.conditions[0]?.reward,
      secondCondition:
        rewards.depositCountPerMonth?.points >=
        rewards.depositCountPerMonth?.conditions[1]?.reward,
    };

    result.MedianDepositPerTransaction = {
      firstCondition:
        rewards.medianDepositPerTransaction?.conditions[1]?.reward ? (rewards.medianDepositPerTransaction?.points >=
            rewards.medianDepositPerTransaction?.conditions[0]?.reward &&
            rewards.medianDepositPerTransaction?.points < rewards.medianDepositPerTransaction?.conditions[1]?.reward) :
          rewards.medianDepositPerTransaction?.points >=
          rewards.medianDepositPerTransaction?.conditions[0]?.reward,
      secondCondition:
        rewards.medianDepositPerTransaction?.points >=
        rewards.medianDepositPerTransaction?.conditions[1]?.reward,
    };

    result.WageringPerMonth = {
      firstCondition:
        rewards.wageringPerMonth?.conditions[1] ? rewards.wageringPerMonth?.points >=
          rewards.wageringPerMonth?.conditions[0]?.reward &&
          rewards.wageringPerMonth?.points < rewards.wageringPerMonth?.conditions[1]?.reward :
          rewards.wageringPerMonth?.points >=
          rewards.wageringPerMonth?.conditions[0]?.reward,
      secondCondition:
        rewards.wageringPerMonth?.points >=
        rewards.wageringPerMonth?.conditions[1]?.reward,
    };

    result.UserProviderCount = {
      firstCondition:
        rewards.differentGameProviders?.points >=
        rewards.differentGameProviders?.conditions[0]?.reward,
    };

    result.ConsecutiveMonthActivity = {
      firstCondition:
        rewards.consecutiveMonthOfActivity?.conditions[1] ? rewards.consecutiveMonthOfActivity?.points >=
          rewards.consecutiveMonthOfActivity?.conditions[0]?.reward &&
          rewards.consecutiveMonthOfActivity?.points < rewards.consecutiveMonthOfActivity?.conditions[1]?.reward :
          rewards.consecutiveMonthOfActivity?.points >=
          rewards.consecutiveMonthOfActivity?.conditions[0]?.reward,
      secondCondition:
        rewards.consecutiveMonthOfActivity?.points >=
        rewards.consecutiveMonthOfActivity?.conditions[1]?.reward,
    };

    return result;

  }

  /**
   * Parse html content for benefits
   * @param htmlString
   * @private
   */
  private _parseHtmlBenefitsToJson(htmlString: string): Array<{
    title: string,
    active: boolean
  }> {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const result = [];

    const cleanText = (text: string): string => {
      return text
        .replace(/<[^>]+>/g, '')
        .replace(/\s+/g, ' ')
        .trim();
    };

    doc.querySelectorAll('p').forEach((pTag) => {
      const strongTag = pTag.querySelector('strong');
      let title: string;

      if (strongTag) {
        title = cleanText(strongTag.textContent);
        if (title) {
          result.push({
            title: title,
            active: true,
          });
        }
      } else {
        title = cleanText(pTag.textContent);
        if (title) {
          result.push({
            title: title,
            active: false,
          });
        }
      }
    });
    return result;
  }

  private _getTournaments$(): Observable<any> {
    return this._tournaments$.list({ with_game_list: 1 }).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments.now.filter(tournament => {
        return tournament.slug.includes('vip');
      })),
    );
  }
}
